import { createGlobalStyle } from "styled-components"


import COLORS from "./styleConstants"

const GlobalStyle = createGlobalStyle`
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inheret;
}



:root {
    font-size: 62.5%; 

    --color-scrollbar: ${COLORS.blueCadet};
    --color-primary-light: ${COLORS.redLight};
    --color-primary-medium: ${COLORS.redMedium};
    --color-primary-dark: ${COLORS.redDark};
    --color-secondary-medium: ${COLORS.greenSeaMedium};
    --color-grey-medium: #708090;
    --color-white: #ffffff;
    --color-white-medium: #fffafa;
    --color-white-dark: #f5f5f5;

    --font-size-small: 1.75rem;
    --font-size-medium: 2.5rem;
    --font-size-h4: 3.5rem;
    --font-size-h3: 10rem;
    --font-size-h2: 20rem;
    --font-size-h1: 22rem;
}


body {

  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: "Josefin Sans", "sans serif";

}

#root{

  display: flex;
  justify-content: center;

}
 
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }


  @media only screen and (min-width: 1800px) {
    :root {
      font-size: 75%;
    }
  }

  
  @media only screen and (max-width: 1200px) {
    :root {

      font-size: 56.25%;
    }
  }

  
  @media only screen and (max-width: 900px){

    :root {
      font-size: 50%;
    }
  }


  @media only screen and (max-width: 770px){

    :root {
      font-size: 45%;
    }
  }

 

`

export default GlobalStyle
